import {
  ImmutableObject,
  SetPartialStateAction,
  none,
  useHookstate,
} from "@hookstate/core"

import { deepClone } from "src/helpers/deepClone"

import { postPurchaseorderRefresh } from "src/queriesXRM/purchaseorder"
import { createAspState } from "src/states/createAspState"
import { xRMApiPurchaseorderProduct } from "src/types/xRM"

const amountProductAttributeName = "Amount"

export type CartProduct = xRMApiPurchaseorderProduct

export type Cart = {
  id: string
  orderId: string
  products: CartProduct[]
  revisionNo: number
}

export type Carts = {
  loading: boolean
  currentCartId: string
  carts: Cart[]
}
export const defaultCarts = {
  loading: false,
  currentCartId: "0",
  carts: [],
} as Carts

export const postRefreshCart = async (
  cart: ImmutableObject<Cart>,
): Promise<CartProduct[]> => {
  if (cart.products.length === 0) return []
  const refreshed = await postPurchaseorderRefresh({
    id: cart.orderId,
    parentSalesorderId: cart.id,
    exhibition: {
      id: process.env.NEXT_PUBLIC_WEBSHOP_XRM_EVENT_ID,
    },
    products: deepClone<CartProduct[]>(
      cart.products.map((p) => ({
        ...p,
        productVariant: {
          id: p.productVariant?.id,
          categoryId: p.productVariant?.categoryId,
          parentCategoryId: p.productVariant?.parentCategoryId,
          price: p.productVariant?.price,
        },
      })),
    ),
  })
  return refreshed.products ?? []
}

const state = createAspState<Carts>({ ...defaultCarts }, "shop-carts")

export const mergeCarts = (items: SetPartialStateAction<Carts>) =>
  state.merge(items)

export const setCarts = (items: Carts) => state.set(items)

export const resetCarts = () => state.set({ ...defaultCarts })

export const getCarts = () => state.get()

export const useCarts = () => useHookstate(state).get()

export const getCartProductAmount = (product: ImmutableObject<CartProduct>) => {
  const amount =
    (product?.productAttributeValues?.find(
      (v) => v.field === amountProductAttributeName,
    )?.value as unknown as number) ?? 0
  return Number(amount)
}

export const mergeCart = (
  product: ImmutableObject<CartProduct>,
  setAmount?: (amount: number) => number,
  id?: string,
) => {
  const productClone = deepClone<CartProduct>(product)
  const cartId = id ?? state.currentCartId.get()
  let cartIdx = state.carts.get().findIndex((cart) => cart.id === cartId)

  if (cartIdx < 0) {
    state.carts.set((carts) => [
      ...carts,
      { id: cartId, orderId: crypto.randomUUID(), products: [], revisionNo: 0 },
    ])
    cartIdx = 0
  }

  const cartProductIdx = state.carts
    .nested(cartIdx)
    .get()
    .products.findIndex(
      (cartProduct) =>
        cartProduct.productVariant?.id === product.productVariant?.id,
    )

  const amount = setAmount
    ? setAmount(getCartProductAmount(product))
    : getCartProductAmount(product)

  if (cartProductIdx < 0) {
    if (amount > 0) {
      state.carts
        .nested(cartIdx)
        .products.set((products) => [...products, productClone])
      state.carts
        .nested(cartIdx)
        .set((c) => ({ ...c, revisionNo: c.revisionNo + 1 }))
    }
    return
  }

  state.carts
    .nested(cartIdx)
    .products.nested(cartProductIdx)
    .set(
      amount > 0
        ? {
            ...productClone,
            productAttributeValues: productClone.productAttributeValues?.map(
              (v) =>
                v.field === amountProductAttributeName
                  ? { ...v, value: amount }
                  : v,
            ),
          }
        : none,
    )

  state.carts
    .nested(cartIdx)
    .set((c) => ({ ...c, revisionNo: c.revisionNo + 1 }))
}

export const setCart = (
  products: CartProduct[],
  ignoreRevisionUpdate?: boolean,
  cart?: Pick<Cart, "id">,
) => {
  const cartId = cart?.id ?? state.currentCartId.get()
  let cartIdx = state.carts.get().findIndex((cart) => cart.id === cartId)

  if (cartIdx < 0) {
    state.carts.set((carts) => [
      ...carts,
      { id: cartId, orderId: crypto.randomUUID(), revisionNo: 0, products },
    ])
    cartIdx = 0
  }

  state.carts.nested(cartIdx).set((cart: Cart) => ({
    ...cart,
    products,
    revisionNo: ignoreRevisionUpdate ? cart.revisionNo : cart.revisionNo + 1,
  }))
}

export const resetCart = (cart?: Pick<Cart, "id">) => {
  const cartId = cart?.id ?? state.currentCartId.get()
  let cartIdx = state.carts.get().findIndex((cart) => cart.id === cartId)

  if (cartIdx < 0) {
    state.carts.set((carts) => [
      ...carts,
      { id: cartId, orderId: crypto.randomUUID(), revisionNo: 0, products: [] },
    ])
    cartIdx = 0
  }

  return state.carts.nested(cartIdx).set((cart: Cart) => ({
    ...cart,
    orderId: crypto.randomUUID(),
    revisionNo: -1,
    products: [],
  }))
}

export const getCart = (cart?: Pick<Cart, "id">) => {
  const cartId = cart?.id ?? state.currentCartId.get()
  let cartIdx = state.carts.get().findIndex((cart) => cart.id === cartId)

  if (cartIdx < 0) {
    state.carts.set((carts) => [
      ...carts,
      { id: cartId, orderId: crypto.randomUUID(), products: [], revisionNo: 0 },
    ])
    cartIdx = 0
  }

  return state.carts.nested(cartIdx).get()
}

export const useCart = (cart?: Pick<Cart, "id">) => {
  const cartId = cart?.id ?? state.currentCartId.get()
  let cartIdx = state.carts.get().findIndex((cart) => cart.id === cartId)

  if (cartIdx < 0) {
    state.carts.set((carts) => [
      ...carts,
      { id: cartId, orderId: crypto.randomUUID(), products: [], revisionNo: 0 },
    ])
    cartIdx = 0
  }

  return useHookstate(state.carts.nested(cartIdx)).get()
}
